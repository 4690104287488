import React from 'react';
import { useTranslation } from '@hooks';
import styles from './ThirdPageBody.module.css';
import TipAiSlide from '../../assets/tip-ai-slide.svg';

const ThirdPageBody = () => {
	const { t } = useTranslation();

	return (
		<div className={styles.wrapper}>
			<p>{t('Our secure AI makes it fast and easy')}</p>
			<img alt="TipAiSlide" src={TipAiSlide} />
		</div>
	);
};

export default ThirdPageBody;
