import LightBox from 'react-image-lightbox';
import { MentionsInput, Mention } from 'react-mentions';
import { useMemo, useState, useRef, useEffect, forwardRef } from 'react';

import { useSearchUsersQuery } from '@hooks';
import { CloseIcon, VideoAttachmentIcon } from '@ui-kit';
import { EXACT_MATCH_PATTERN, MENTION_MATCH_PATTERN } from '@constants';

import FileTypeAvatar from '../FileTypeAvatar';
import { useImageView } from './useImageView';
import styles from './AttachmentTextArea.module.css';

import './AttachmentTextArea.overwrite.css';

import {
	filterImageUrls,
	suggestionTitle,
	mapSuggestionData,
	handleRemoveAttachment as removeAttachmentUtil,
} from './utils';

import SuggestionsWrapper from './SuggestionsWrapper';
import SuggestionListItem from '../SuggestionListItem';

import type { ForwardedRef } from 'react';
import type { TAttachments, TCommunity, TFullUser } from '@typings';
import type { MentionsInputProps, SuggestionDataItem } from 'react-mentions';

const AttachmentTextArea = (
	{
		value,
		placeholder,
		disabled,
		attachments,
		attachmentsError,
		attachmentEditingDisabled,
		onChange,
		onRemoveAttachment,
		resize,
		delayedResize,
		onPaste,
		networkId,
		...props
	}: IAttachmentTextAreaProps,
	ref: ForwardedRef<HTMLTextAreaElement>,
) => {
	const mentionInputRef = useRef<any | null>(null); // FIXME
	const [isFocused, setIsFocused] = useState(false);

	const imagesUrls = useMemo(() => filterImageUrls(attachments), [attachments]);

	const { currentImage, nextImage, prevImage, handleClick, handleClose, handleMovePrev, handleMoveNext } =
		useImageView(imagesUrls);

	const isExactAtQuery = EXACT_MATCH_PATTERN.test(value?.trim() || '');
	const isMentionQuery = MENTION_MATCH_PATTERN.test(value?.trim() || '');
	const {
		data: users,
		isLoading,
		isFetching,
	} = useSearchUsersQuery({
		searchFilter: value?.split('@').pop() || '',
		communityId: networkId,
		options: { enabled: isExactAtQuery || isMentionQuery },
	});

	const suggestionData = useMemo(() => mapSuggestionData(users), [users]);

	const mentions = useMemo(
		() => [
			<Mention
				appendSpaceOnAdd
				className={styles.mentionHighlighted}
				data={suggestionData ?? []}
				isLoading={isFetching && isLoading && (isExactAtQuery || isMentionQuery)}
				key={0}
				renderSuggestion={(suggestion, search, highlightedDisplay, _, focused) => (
					<SuggestionListItem
						focused={focused}
						highlightedDisplay={highlightedDisplay}
						suggestion={suggestion as TFullUser & SuggestionDataItem}
					/>
				)}
				trigger={'@'}
			/>,
		],
		[suggestionData, isFetching, isLoading, isExactAtQuery, isMentionQuery],
	);

	useEffect(() => {
		if (suggestionData?.length && mentionInputRef?.current?.state?.suggestions?.[mentions?.length - 1]?.results) {
			mentionInputRef.current.state.suggestions[mentions?.length - 1].results = suggestionData;
			mentionInputRef.current.updater.enqueueForceUpdate(mentionInputRef.current);
		}
	}, [suggestionData]);

	return (
		<>
			<div className={styles.textarea__container} data-focused={isFocused}>
				{attachments.length > 0 && (
					<>
						{attachmentsError && <span className={styles.attachment__error}>{attachmentsError}</span>}
						<div className={styles.attachment__container}>
							{attachments.map((attachment, index) => (
								<div className={styles.attachment} key={`${attachment.name}-${attachment.url}-${index}`}>
									{!attachmentEditingDisabled && (
										<button
											className={styles.attachment__button}
											onClick={() => removeAttachmentUtil(onRemoveAttachment, attachment)}
										>
											<CloseIcon height={8} width={8} />
										</button>
									)}
									{attachment.fileType === 'image' ? (
										<button
											className={styles.attachment__buttonImg}
											onClick={() =>
												handleClick(imagesUrls.findIndex((img: string | undefined) => img === attachment?.url))
											}
										>
											<img alt={attachment.name} className={styles.attachment} src={attachment.url} />
										</button>
									) : attachment.fileType === 'file' ? (
										<div className={styles.attachment__file}>
											<FileTypeAvatar mimeType={attachment?.mimeType || ''} />
										</div>
									) : (
										<div>
											<video controls height="60" width="60">
												<source src={attachment.url} />
												<track default kind="captions" src={attachment.url} srcLang="en" />
											</video>
											<VideoAttachmentIcon className={styles.attachment__videoIcon} height={16} width={16} />
										</div>
									)}
								</div>
							))}
						</div>
					</>
				)}

				<MentionsInput
					allowSpaceInQuery
					autoFocus
					customSuggestionsContainer={(children) => (
						<SuggestionsWrapper
							attachments={attachments}
							isLoading={isLoading && isFetching}
							title={suggestionTitle(isExactAtQuery)}
						>
							{children}
						</SuggestionsWrapper>
					)}
					disabled={disabled}
					forceSuggestionsAboveCursor
					inputRef={ref}
					placeholder={placeholder}
					ref={mentionInputRef as any} // FIXME
					rows={1}
					style={{
						suggestions: {
							list: {
								maxHeight: 284,
								overflowY: 'auto',
							},
						},
					}}
					value={value}
					onBlur={() => setIsFocused(false)}
					onChange={(event) => {
						resize();
						onChange?.(event.target.value);
					}}
					onCut={delayedResize}
					onDrop={delayedResize}
					onFocus={() => setIsFocused(true)}
					onKeyDown={delayedResize}
					onPaste={(event) => {
						onPaste?.(event);
						delayedResize();
					}}
					{...props}
					className="mentionTextarea"
				>
					{mentions}
				</MentionsInput>
			</div>
			{!!currentImage && (
				<LightBox
					enableZoom={false}
					mainSrc={currentImage}
					mainSrcThumbnail={currentImage}
					nextSrc={nextImage}
					nextSrcThumbnail={nextImage}
					prevSrc={prevImage}
					prevSrcThumbnail={prevImage}
					onCloseRequest={handleClose}
					onMoveNextRequest={handleMoveNext}
					onMovePrevRequest={handleMovePrev}
				/>
			)}
		</>
	);
};

export interface IAttachmentTextAreaProps {
	value?: string;
	placeholder?: string;
	disabled?: boolean;
	attachmentEditingDisabled?: boolean;
	attachmentsError?: string;
	attachments: TAttachments[];
	onRemoveAttachment: (file: TAttachments) => void;
	delayedResize: () => void;
	resize: () => void;
	onChange: (value: string) => void;
	onPaste: MentionsInputProps['onPaste'];
	networkId?: TCommunity['id'];
}

export default forwardRef(AttachmentTextArea);
