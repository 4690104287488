import { useQuery, useService } from '@hooks';

import type { TCommunity, TFullUser } from '@typings';
import type { UseQueryOptions, UseQueryResult } from '@tanstack/react-query';

export const useSearchUsersQuery = ({
	searchFilter,
	communityId,
	options,
}: IUseGetUsersQueryProps): UseQueryResult<TFullUser[], unknown> => {
	const api = useService('ApiService');

	return useQuery(['user.getUsers', searchFilter, communityId], () => api.user.getUsers(searchFilter, communityId), {
		...options,
		cacheTime: 0,
		staleTime: 0,
		enabled: options?.enabled,
	});
};

interface IUseGetUsersQueryProps {
	searchFilter: string;
	communityId?: TCommunity['id'];
	options?: UseQueryOptions<TFullUser[], Error, TFullUser[], any[]>;
}
