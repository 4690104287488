import { startTransition } from 'react';
import {
	Button,
	EditIcon,
	Modal,
	NotificationIcon,
	NotificationOffIcon,
	SearchableScreenBar,
	SpacedGroup,
	VisuallyHidden,
	When,
} from '@ui-kit';
import Feed from '@ui-modules/feed/components/Feed';
import PinnedBanners from '@ui-modules/feed/components/PinnedBanners';
import FeedSearchResultList from '@ui-modules/feed/components/FeedSearchResultList';
import { useMe, useNavigate, useService, useState, useToggle, useTranslation } from '@hooks';
import { useCommunityQuery } from '@ui-modules/communities/hooks/useCommunityQuery';
import { useCommunityNotifications } from '@ui-modules/networks/hooks/useCommunityNotifications';
import { CommunityEntity } from '@utils';
import { ROUTES } from '@constants';
import styles from './NetworkDiscussions.module.css';
import type { TCommunity } from '@typings';
import { useCollapsedNotificationsSettings } from '@ui-modules/settings/hooks/useNotificationSettingsVisibility';

const NetworkDiscussions = ({ networkId, initialSearchQuery = '', onChangeSearchQuery }: INetworkDiscussionsProps) => {
	const { t } = useTranslation();
	const navigate = useNavigate();
	const analytics = useService('AnalyticsService');
	const { user } = useMe();

	const { addCollapsedNotificationsSettings, removeCollapsedNotificationsSettings } =
		useCollapsedNotificationsSettings();
	const { data: network } = useCommunityQuery(networkId);
	const isOrganizer = Boolean(network?.membership?.moderatorType.includes('organizer'));
	const isModerator = Boolean(network?.membership?.moderatorType.includes('moderator'));

	const [searchQuery, setSearchQuery] = useState(initialSearchQuery, 'NetworkDiscussions:searchQuery');
	const [isSearchFocused, setIsSearchActive] = useToggle(false);
	const showSearchResults = isSearchFocused || !!searchQuery.length;

	const {
		alertsOn,
		mutateNotifications,
		isLoadingNotificationsSettings,
		isLoading: alertsMutationLoading,
	} = useCommunityNotifications({ communityId: networkId as string });
	const [modalVisible, setModalVisible] = useToggle(false);
	const openNewPostPage = () => navigate(ROUTES.addPost(), { state: { networkId: network?.['@id'] } });

	const onAlertsToggle = async () => {
		const networkIdentifier = `Networks_${networkId}`;

		if (alertsOn) {
			setModalVisible(true);
		} else {
			mutateNotifications({ alertsOn: true, notificationId: 'post_created', constraint: 'community' });
			mutateNotifications({ alertsOn: true, notificationId: 'new_comment', constraint: 'community' });
			mutateNotifications({ alertsOn: true, notificationId: 'new_discussion_post_like', constraint: 'community' });
			(isOrganizer || isModerator) &&
				mutateNotifications({ alertsOn: true, notificationId: 'new_comment_for_moderator', constraint: 'community' });
			addCollapsedNotificationsSettings(networkIdentifier);
		}
		analytics.trackEvent('NetworkInteractions', {
			page_name: String(network?.name),
			action_taken: alertsOn ? 'alerts on' : 'alerts off',
		});
	};

	if (!network) return null;
	return (
		<>
			<div className={styles.networkDiscussions__headerContainer}>
				<div className={styles.networkDiscussions__header}>
					<SearchableScreenBar
						placeholder={t('Search posts')}
						searchQuery={searchQuery}
						searchTitle={t('Search')}
						onBlur={() => setIsSearchActive(false)}
						onChangeSearchQuery={(searchQuery) => {
							setSearchQuery(searchQuery);
							onChangeSearchQuery(searchQuery);
						}}
						onFocus={() => setIsSearchActive(true)}
					>
						<SpacedGroup>
							<When condition={CommunityEntity.shouldShowDiscussionsV2(network, user.roles)}>
								<Button
									icon={<EditIcon fill="#000000" height={14} width={14} />}
									iconPosition="left"
									title={t('New Post')}
									variant="small"
									onClick={openNewPostPage}
								/>
							</When>
							<Button
								icon={
									!alertsOn ? (
										<NotificationIcon className={styles.networkDiscussions__notificationIcon} height={14} width={22} />
									) : (
										<NotificationOffIcon
											className={styles.networkDiscussions__notificationIcon}
											height={20}
											width={20}
										/>
									)
								}
								loading={isLoadingNotificationsSettings || alertsMutationLoading}
								title={alertsOn ? t('Mute') : t('Unmute')}
								variant="small"
								onClick={onAlertsToggle}
							/>
						</SpacedGroup>
					</SearchableScreenBar>
				</div>
			</div>

			<When condition={!!showSearchResults}>
				<FeedSearchResultList
					communityName={network.name}
					searchQuery={searchQuery}
					onSearch={(searchQuery) => {
						startTransition(() => {
							onChangeSearchQuery(searchQuery);
						});
					}}
				/>
			</When>
			<VisuallyHidden hidden={showSearchResults}>
				<PinnedBanners predicateToFilter={(banner) => banner?.community?.includes(networkId)} />
			</VisuallyHidden>

			<Feed hidden={showSearchResults} isActivitySourceHidden userId={network.slug} />

			<Modal
				cancelTitle={t('Cancel')}
				confirmTitle={t('Confirm')}
				modalContent={
					<div>
						<p className={styles.networkDiscussions__modalContent}>
							{t('This will turn off all Notifications, both email and messages to your device.')} {t('Please go to')}
							<span
								role="presentation"
								onClick={() => {
									navigate(ROUTES.notificationSettingsGroup('Networks'));
									setModalVisible(false);
								}}
							>
								{t('Settings -> Notifications -> Networks')}
							</span>
							{t('if you only want to turn off one of these options.')}
						</p>
					</div>
				}
				variant="medium"
				visible={modalVisible}
				onCancel={() => {
					setModalVisible(false);
				}}
				onConfirm={async () => {
					setModalVisible(false);
					await mutateNotifications({
						alertsOn: false,
						notificationId: 'post_created',
						constraint: 'community',
					});
					await mutateNotifications({
						alertsOn: false,
						notificationId: 'new_comment',
						constraint: 'community',
					});
					(isOrganizer || isModerator) &&
						(await mutateNotifications({
							alertsOn: false,
							notificationId: 'new_comment_for_moderator',
							constraint: 'community',
						}));

					const networkIdentifier = `Networks_${networkId}`;
					removeCollapsedNotificationsSettings(networkIdentifier);
				}}
			/>
		</>
	);
};

export interface INetworkDiscussionsProps {
	networkId: TCommunity['id'];
	initialSearchQuery: string;
	onChangeSearchQuery: (searchQuery: string) => void;
}

export default NetworkDiscussions;
