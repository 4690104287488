import TipCard from '@ui-modules/tipJar/components/TipCard';
import type { TProfile, TTip } from '@typings';
import { useCallback } from 'react';
import { useBlockedAndBlockingUsers, useNavigate, useRestoreVirtuosoInitialIndex } from '@hooks';
import type { ItemContent } from 'react-virtuoso';
import { Virtuoso } from 'react-virtuoso';
import { Gap } from '@ui-kit';
import { ROUTES } from '@constants';
import ActivityIndicator from '@ui-kit/components/ActivityIndicator';

const TipList = ({ data: tips = [], highlightedText, onEndReached, isFetchingNextPage }: ITipListProps) => {
	const navigate = useNavigate();
	const { blockedAndBlockingUsers } = useBlockedAndBlockingUsers();
	const { initialTopMostItemIndex, onRangeChanged } = useRestoreVirtuosoInitialIndex('myTips');
	const openProfile = (profileId: TProfile['id']) => navigate(ROUTES.memberProfile(profileId));

	const renderTipItem = useCallback<ItemContent<TTip, unknown>>(
		(_, tip) => {
			if (!tip || blockedAndBlockingUsers.some((blockedUser) => blockedUser.id === tip.owner.id)) {
				return <Gap gap={1} />;
			}
			return (
				<TipCard
					highlightedText={highlightedText}
					key={tip.id}
					tip={tip}
					onClick={() => navigate(ROUTES.tipPage(tip.id))}
					onProfileClick={() => openProfile(tip.owner.profileId)}
				/>
			);
		},
		[blockedAndBlockingUsers, highlightedText],
	);
	return (
		<Virtuoso
			components={{
				Footer: () => (isFetchingNextPage ? <ActivityIndicator size={'small'} type="fit" /> : <Gap gap={8} />),
			}}
			data={tips}
			defaultItemHeight={137}
			endReached={onEndReached}
			initialTopMostItemIndex={initialTopMostItemIndex}
			itemContent={renderTipItem}
			rangeChanged={onRangeChanged}
			totalCount={tips?.length}
		/>
	);
};

export interface ITipListProps {
	data: TTip[];
	isFetched?: boolean;
	isLoading: boolean;
	isFetchingNextPage: boolean;
	highlightedText?: string;
	onEndReached: () => void;
}

export default TipList;
