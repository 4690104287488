import { useRef, useTranslation, useDebounce, useClickAway, useService } from '@hooks';
import {
	Link,
	HomeActiveIcon,
	HomeIcon,
	IconButton,
	UsersIcon,
	UsersActiveIcon,
	NetworkIcon,
	NetworkActiveIcon,
	LearnIcon,
	CalendarActiveIcon,
	CalendarIcon,
	LearnActiveIcon,
	InputSearch,
	DealsIcon,
	ActiveDealsIcon,
	SupportIcon,
	SupportActiveIcon,
	LampFilledIcon,
	LampIcon,
} from '@ui-kit';
import { GLOBAL_SEARCH_INPUT_ID_DOM_ATTRIBUTE, ROUTES } from '@constants';
import { useGlobalSearchState } from '@ui-modules/globalSearch/hooks/useGlobalSearchState';
import { useGlobalSearchHistory } from '@ui-modules/globalSearch/hooks/useGlobalSearchHistory';
import { useTabBarContext } from './TabBarContext';
import { ChatHeaderButton } from '../ChatHeaderButton';
import { ProfileHeaderButton } from '../ProfileHeaderButton';
import { NotificationsHeaderButton } from '../NotificationsHeaderButton';
import ImpersonateBanner from '@ui-modules/auth/components/ImpersonateBanner';
import logo from '../../assets/t21-connect-logo.svg';
import styles from './TabBar.module.css';
import SearchModal from '@ui-modules/globalSearch/components/SearchModal/SearchModal';
import { useRouteState } from './useRouteState';
import { useDealsEnabled } from '@ui-modules/deals/hooks/useDealsEnabled';
import type { Ref, RefObject } from 'react';

const TabBar = ({ isNotificationsShown, notificationsButtonRef, toggleNotificationsSection }: ITabBarProps) => {
	const { t } = useTranslation();
	const { queryClient, queryKeys } = useService('ReactQueryService');
	const analytics = useService('AnalyticsService');

	const searchInputRef = useRef() as RefObject<HTMLInputElement>;

	const forceTabName = useTabBarContext().getForceSetSelectedTabName();
	const { isSearchActive, setIsSearchActive, searchQuery, setSearchQuery } = useGlobalSearchState();
	const [debouncedSearchQuery] = useDebounce(searchQuery, 400);
	const { addRecentSearch } = useGlobalSearchHistory(searchQuery, debouncedSearchQuery);
	const { enabled } = useDealsEnabled();

	const portalRef = useRef() as RefObject<HTMLDivElement>;
	useClickAway(portalRef, () => setIsSearchActive(false));
	const { postTab } = useRouteState();

	return (
		<header className={styles.header}>
			<ImpersonateBanner />
			<div className={styles.header__container} id={styles['header']}>
				<Link to={ROUTES.home()}>
					<img alt="logo" className={styles.header__logo} height={40} src={logo} width={181} />
				</Link>
				<div className={styles.header__navigation}>
					<IconButton
						extraMatch
						forcedActive={postTab === 'Home'}
						icon={<HomeIcon className={styles.header__icon} height={20} width={23} />}
						iconActive={<HomeActiveIcon className={styles.header__icon_active} height={20} width={23} />}
						label={t('Home')}
						linkTo={ROUTES.home()}
					/>
					<IconButton
						icon={<UsersIcon className={styles.header__icon} height={20} width={25} />}
						iconActive={<UsersActiveIcon className={styles.header__icon_active} height={20} width={25} />}
						label={t('Groups')}
						linkTo={ROUTES.groups()}
					/>
					{enabled && (
						<IconButton
							forcedActive={forceTabName === 'invest'}
							icon={<DealsIcon className={styles.header__icon} height={20} width={25} />}
							iconActive={<ActiveDealsIcon className={styles.header__icon_active} height={20} width={25} />}
							label={t('Invest')}
							linkTo={ROUTES.deals()}
							onClick={() => {
								queryClient.refetchQueries(queryKeys.getAllDealFilters());
							}}
						/>
					)}
					<IconButton
						forcedActive={postTab === 'Networks' || forceTabName === 'networks'}
						icon={<NetworkIcon className={styles.header__icon} height={20} width={25} />}
						iconActive={<NetworkActiveIcon className={styles.header__icon_active} height={20} width={25} />}
						label={t('Networks')}
						linkTo={ROUTES.networks()}
					/>
					<IconButton
						icon={<CalendarIcon className={styles.header__icon} height={20} width={18} />}
						iconActive={<CalendarActiveIcon className={styles.header__icon_active} height={20} width={18} />}
						label={t('Calendar')}
						linkTo={ROUTES.calendar()}
					/>
					<IconButton
						forcedActive={postTab === 'Learn'}
						icon={<LearnIcon className={styles.header__icon} height={22} width={17} />}
						iconActive={<LearnActiveIcon className={styles.header__icon_active} height={22} width={17} />}
						label={t('Learn')}
						linkTo={ROUTES.learn()}
					/>
					<IconButton
						icon={<LampIcon className={styles.header__icon} height={28} width={17} />}
						iconActive={<LampFilledIcon className={styles.header__icon_active} height={28} width={17} />}
						label={t('Tip Jar')}
						linkTo={ROUTES.tipJar()}
					/>
				</div>
				<div className={styles.header__globalSearch}>
					<InputSearch
						addRecentSearch={addRecentSearch}
						id={GLOBAL_SEARCH_INPUT_ID_DOM_ATTRIBUTE}
						ref={searchInputRef}
						searchQuery={searchQuery}
						setIsSearchActive={setIsSearchActive}
						setSearchQuery={setSearchQuery}
					/>
				</div>

				<div className={styles.header__navigation}>
					<ChatHeaderButton />
					<IconButton
						icon={<SupportIcon className={styles.header__icon} height={20} width={20} />}
						iconActive={<SupportActiveIcon className={styles.header__icon_active} height={20} width={20} />}
						label={t('Support')}
						linkTo={ROUTES.staffAndSupport()}
					/>
					<NotificationsHeaderButton
						isActive={isNotificationsShown}
						ref={notificationsButtonRef}
						onClick={() => {
							toggleNotificationsSection();
							analytics.trackEvent('NotificationInteractions', {
								action_taken: 'notification button opened',
							});
						}}
					/>
					<ProfileHeaderButton />
				</div>
			</div>
			{isSearchActive ? <SearchModal searchInputRef={searchInputRef} /> : null}
		</header>
	);
};

interface ITabBarProps {
	isNotificationsShown: boolean;
	notificationsButtonRef?: Ref<HTMLAnchorElement>;
	toggleNotificationsSection: () => void;
}

export default TabBar;
