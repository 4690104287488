import React, { memo } from 'react';
import styles from './StarsRating.module.css';
import { StarFilledIcon, StarIcon } from '@ui-kit';

const StarsRating: React.FC<IStarsRatingProps> = ({ rating, iconSize = 16, label, onClick, name }) => {
	return (
		<div className={styles.starRating}>
			<div aria-label={label} className={styles.starRating__stars} role="radiogroup" tabIndex={0}>
				{Array.from({ length: 5 }).map((_, index) => {
					const filled = index < rating;
					return (
						<div
							aria-checked={filled}
							aria-labelledby={`star-${index}`}
							className={styles.starRating__button}
							key={index}
							role="radio"
							style={{ cursor: onClick ? 'pointer' : 'default' }}
							tabIndex={0}
							onClick={() => onClick?.(index + 1)}
							onKeyDown={(e) => {
								if (e.key === 'Enter' || e.key === ' ') {
									onClick?.(index + 1);
								}
							}}
						>
							{filled ? (
								<StarFilledIcon height={iconSize} width={iconSize} />
							) : (
								<StarIcon height={iconSize} width={iconSize} />
							)}
						</div>
					);
				})}
				{name && <input name={name} type="hidden" value={rating} />}
			</div>
			{label && <span className={styles.starRating__label}>{label}</span>}
		</div>
	);
};

export interface IStarsRatingProps {
	rating: number;
	label?: string;
	iconSize?: number;
	onClick?: (newRating: number) => void;
	name?: string;
}

export default memo(StarsRating);
