import { FormPageLayout, Page } from '../../components';
import { useTranslation, useNavigate, useLocation, useState, useEffect, useMemo } from '@hooks';
import styles from './SelectTagsPage.module.css';
import { ActivityIndicator, FieldArray, FilterInput, FixedWidthContainer, Formik } from '@ui-kit';
import { useTagsQuery } from '@ui-modules/networks/hooks/useTagsQuery';
import { ROUTES } from '../../routes';
import TagList from '@ui-modules/profile/components/TagList';
import { pickUpTitleByType, pickUpDescriptionByType } from '@utils';
import type { TTagsType } from '@typings';
import { useProfileTags } from '@ui-modules/profile/hooks/useProfileTags';

const SelectTagsPage = () => {
	const { t } = useTranslation();
	const navigate = useNavigate();
	const location: ILocationState = useLocation();
	const {
		state: { tagsType, selectedTags },
	} = location;

	const { saveSelectedTags, selectedTags: initialSelectedTags } = useProfileTags();
	const { tags = [], createTag, isTagsLoading } = useTagsQuery(tagsType || 'expertise', selectedTags || []);
	const initialValues = useMemo(
		() => ({ selectedTags: [...(initialSelectedTags || [])].sort() }),
		[initialSelectedTags],
	);
	const handleSubmit = async (values: TTagSearchForm) => {
		saveSelectedTags(values.selectedTags);
	};
	const [filterQuery, setFilterQuery] = useState('');

	useEffect(() => {
		if (!location.state) {
			navigate(ROUTES.profile(), { replace: true });
		}
	}, []);

	return (
		<Page title={pickUpTitleByType(tagsType, t)}>
			<Formik<TTagSearchForm> initialValues={initialValues} onSubmit={handleSubmit}>
				{(formProps) => {
					const { values, setFieldValue } = formProps;
					const removeTag = (tag: string) =>
						setFieldValue(
							'selectedTags',
							values.selectedTags.filter((selectedTag) => selectedTag !== tag),
						);
					const addTag = (tag: string) => setFieldValue('selectedTags', [...values.selectedTags, tag].sort());
					return (
						<FormPageLayout
							allowedNextPaths={[ROUTES.editProfileTags()]}
							formProps={formProps}
							headerTitle={pickUpTitleByType(tagsType, t)}
							saveButtonTitle={t('Done')}
							onDiscarded={() => saveSelectedTags(selectedTags)}
						>
							{isTagsLoading ? (
								<ActivityIndicator type="fit" />
							) : (
								<div className={styles.investment}>
									<FieldArray
										name="selectedTags"
										render={() => (
											<TagList
												createButtonTitle={t('Create/select')}
												filterQuery={filterQuery}
												ListHeaderComponent={
													<FixedWidthContainer>
														<div className={styles.investment__search}>
															<span className={styles.investment__label}>{pickUpDescriptionByType(tagsType, t)}</span>
															<FilterInput
																placeholder={t('Search...')}
																value={filterQuery}
																onChange={(e) => setFilterQuery(e.target.value)}
																onSearchCleared={() => setFilterQuery('')}
															/>
														</div>
														<div className={styles.divider} />
													</FixedWidthContainer>
												}
												selectedTags={values.selectedTags}
												tags={tags}
												onAddTag={addTag}
												onCreateTag={(newTag: string) => {
													setFilterQuery('');
													const trimmedNewTag = newTag.trim();
													createTag(trimmedNewTag);
													addTag(trimmedNewTag);
												}}
												onRemoveTag={removeTag}
											/>
										)}
									/>
								</div>
							)}
						</FormPageLayout>
					);
				}}
			</Formik>
		</Page>
	);
};

export type TTagSearchForm = {
	selectedTags: string[];
};

export interface ISelectTagsPageParams extends Record<string, string | undefined> {
	id: string;
}

export interface ILocationState {
	state: {
		selectedTags: string[];
		tagsType: TTagsType;
	};
}
export default SelectTagsPage;
