import { Avatar, ChevronRightIcon, HighlightedText } from '@ui-kit';
import { dayjs } from '@utils';
import type { TTip } from '@typings';
import styles from './TipCard.module.css';
import RibbonFlag from '@ui-kit/components/RibbonFlag';
import StarsRating from '@ui-kit/components/StarsRating';
import { useTranslation } from 'react-i18next';
import type { MouseEventHandler } from 'react';
import { noop } from '@utils';

const TipCard = ({ tip, highlightedText, onClick, onProfileClick }: ITipCardProps) => {
	const { t } = useTranslation();

	return (
		<div className={styles.tipCard}>
			<div className={styles.tipCard__inner} onClick={onClick} onKeyDown={noop}>
				<div className={styles.tipCard__imageContainer}>
					<img alt={'Tip Jar Img'} src={tip.photoUrl} />
				</div>
				<div className={styles.tipCard__mainContainer}>
					<RibbonFlag className={styles.tipCard__category} color={'#c8c8c8'} size="small" title={tip.category} />
					<HighlightedText className={styles.tipCard__title} highlightedText={highlightedText}>
						{tip.title}
					</HighlightedText>

					<div className={styles.tipCard__titleRating}>
						<StarsRating
							label={t('{{reviewsCount}} reviews', { reviewsCount: tip.reviewsCount })}
							rating={tip.rating}
						/>
					</div>
					{'owner' in tip ? (
						<div
							className={styles.tipCard__userContainer}
							onClick={(e) => {
								onProfileClick?.(e);
								e.stopPropagation();
							}}
							onKeyDown={noop}
						>
							<Avatar
								className={styles.tipCard__userAvatar}
								outline={tip.owner.roles.includes('ROLE_CHAIR')}
								size={32}
								source={tip.owner.avatarUrl}
								title={tip.owner.fullName}
							/>
							<div className={styles.tipCard__userInfo}>
								<span>{tip.owner.fullName}</span>
								<span>{dayjs(tip.createdAt).format('D MMM YYYY')}</span>
							</div>
						</div>
					) : null}
				</div>
				<div className={styles.tipCard__iconContainer}>
					<ChevronRightIcon fill={'#c8c8c8'} height={18} width={16} onClick={onClick} />
				</div>
			</div>
		</div>
	);
};
export type TTipMatch = Omit<TTip, 'owner'>;

export interface ITipCardProps {
	/** Tip data to display */
	tip: TTipMatch | TTip;
	/** Text to highlight in the title */
	highlightedText?: string;
	/** Callback to handle card press */
	onClick: () => void;
	/** Callback to handle profile press */
	onProfileClick?: MouseEventHandler<HTMLDivElement> | undefined;
}

export default TipCard;
