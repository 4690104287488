import { Page } from '../../components';
import { useTranslation } from '@hooks';
import TipList from '@ui-modules/tipJar/components/TipList';
import { useMyTipsQuery } from '@ui-modules/tipJar/hooks/useMyTipsQuery';
import { ActivityIndicator } from '@ui-kit';
import EmptyTipList from '@ui-modules/tipJar/components/EmptyTipList';

const MyTipsPage = () => {
	const { t } = useTranslation();
	const { data: tips, isLoading, fetchMore, isFetchingNextPage, isFetched } = useMyTipsQuery();

	return (
		<Page title={t('My Tips Page')}>
			{isLoading && <ActivityIndicator size="medium" type="fit" />}
			{!isLoading && isFetched && tips && tips.length === 0 && <EmptyTipList message={t('No tips available')} />}
			{!isLoading && isFetched && tips && tips.length > 0 && (
				<TipList
					data={tips}
					isFetched={isFetched}
					isFetchingNextPage={isFetchingNextPage}
					isLoading={isLoading}
					onEndReached={fetchMore}
				/>
			)}
		</Page>
	);
};

export default MyTipsPage;
