import { LampIcon } from '@ui-kit';
import styles from './EmptyTipList.module.css';

const EmptyTipList = ({ message }: IEmptyTipListProps) => {
	return (
		<div className={styles.emptyTipList}>
			<div className={styles.emptyTipList__inner}>
				<div>
					<LampIcon height={100} width={100} />
				</div>
				<div>{message}</div>
			</div>
		</div>
	);
};

interface IEmptyTipListProps {
	message: string;
}

export default EmptyTipList;
