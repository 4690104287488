import { AccountMenu, Page, PageLayout } from '../../components';
import {
	useEffect,
	useEvent,
	useGetProfileQuery,
	useHandleBackButton,
	useMemo,
	useNavigate,
	useParams,
	useTrackAlgoliaEventEffect,
	useTranslation,
} from '@hooks';
import styles from './ProfilePage.module.css';
import { ProfilePhoto } from '@ui-modules/profile/components/ProfilePhoto';
import { ROUTES } from '../../routes';
import ProfileExpertise from '@ui-modules/profile/components/ProfileExpertise';
import ProfileMyRole from '@ui-modules/profile/components/ProfileMyRole';
import clsx from 'clsx';
import { ActivityIndicator, BackIcon, Gap } from '@ui-kit';
import ProfileInvestmentExpertise from '@ui-modules/profile/components/ProfileInvestmentExpertise';
import ProfileInterests from '@ui-modules/profile/components/ProfileInterests';
import ProfileActivities from '@ui-modules/profile/components/ProfileActivities';
import { useLatestActivities } from '@ui-modules/feed/hooks/useLatestActivitiesQuery';
import ProfileEvents from '@ui-modules/profile/components/ProfileEvents';
import ProfileGroups from '@ui-modules/profile/components/ProfileGroups';
import ProfileBiography from '@ui-modules/profile/components/ProfileBiography';
import ProfileDetails from '@ui-modules/profile/components/ProfileDetails';
import type { TCommunityCompact, TLatestActivity, TProfileEvent, TUser } from '@typings';
import { useProfileTags } from '@ui-modules/profile/hooks/useProfileTags';
import { getEventId, isUserFeatureEnabled } from '@utils';
import ProfileLeadingGroups from '@ui-modules/profile/components/ProfileLeadingGroups';
import ProfileDeals from '@ui-modules/profile/components/ProfileDeals';
import { useDealsEnabled } from '@ui-modules/deals/hooks/useDealsEnabled';
import { useImpersonate } from '@ui-modules/auth/hooks/useImpersonate';

const ProfilePage = () => {
	const { t } = useTranslation();
	const { profileId } = useParams();
	const navigate = useNavigate();
	const { onBackButtonPress } = useHandleBackButton();
	const { canImpersonate, impersonate } = useImpersonate();

	const {
		data: profile,
		isMe,
		isSectionInvisible,
		isPropertyHidden,
		fullName,
		isLoading,
	} = useGetProfileQuery(profileId);
	const openContact = profile?.user.roles.includes('ROLE_FORMER')
		? undefined
		: () => navigate(ROUTES.contact(String(profile?.id)));

	const { enabled: isInvestTabShowed } = useDealsEnabled();
	const isProfileInvestEnabled =
		isUserFeatureEnabled(profile?.user as TUser, 'invest') && profile?.user?.roles?.includes('ROLE_INVESTOR');
	const { data: activities = [], isLoading: isActivitiesLoading } = useLatestActivities(profile?.user?.id);
	const { clearSelectedTags } = useProfileTags();

	const onEditExpertise = () => navigate(ROUTES.editProfileExpertise());
	const onEditProfile = () => navigate(ROUTES.editProfileInformation());
	const onEditInvestmentExpertise = () => navigate(ROUTES.editProfileInvestmentExpertise());
	const onEditInterests = () => navigate(ROUTES.editProfileInterests());
	const onEditActivities = () => navigate(ROUTES.editProfileActivities());
	const openActivity = (activity: TLatestActivity) => navigate(ROUTES.post(activity?.streamActivityId));
	const onEditEvents = () => navigate(ROUTES.editProfileEvents());
	const openEventOrMeeting = useEvent((event: TProfileEvent) => {
		const eventId = getEventId(event);
		if (event['@type'] === 'Event') {
			navigate(ROUTES.calendarEvent(eventId));
		} else if (event['@type'] === 'Meeting') {
			navigate(ROUTES.calendarMeeting(eventId));
		}
	});
	const onSeeAllEvents = () => navigate(ROUTES.profileEvents(String(profile?.id)));
	const openGroup = useEvent((group: TCommunityCompact) => navigate(ROUTES.groupTab(group.id)));
	const openCommunity = useEvent((community: TCommunityCompact) => {
		if (community.definition === 'group') {
			navigate(ROUTES.groupTab(community.id));
		} else if (community.definition === 'chapter') {
			navigate(ROUTES.chapterTab(community.id));
		} else {
			navigate(ROUTES.networkTab(community.id));
		}
	});
	const openNomination = useEvent(() => navigate(ROUTES.referAssociate()));
	const openRequestAttend = useEvent((group: TCommunityCompact) => navigate(ROUTES.groupTab(group.id)));
	const onEditBiography = () => navigate(ROUTES.editProfileBiography());
	const onEditDetails = () => navigate(ROUTES.editProfileDetails());

	const backButton = useMemo(() => {
		return isMe ? null : (
			<div className={styles.profile__buttonContainer}>
				<button className={styles.profile__button} onClick={onBackButtonPress}>
					<BackIcon className={styles.profile__buttonIcon} height={16} width={16} />
				</button>
			</div>
		);
	}, [isMe]);

	useEffect(() => {
		clearSelectedTags();
	}, []);

	useTrackAlgoliaEventEffect('User Profile Viewed', { userSlug: String(profile?.user.id) }, [!isMe, profile?.user.id]);

	return (
		<Page title={t('Profile')}>
			<PageLayout containerMode={false} sidebarContent={isMe ? <AccountMenu /> : null}>
				{isLoading ? <ActivityIndicator type="fit" /> : null}
				{profile ? (
					<div className={styles.profile}>
						{backButton}
						<div className={clsx(styles.profile__container, !isMe && styles.profile__container_full)}>
							<ProfilePhoto
								canEdit={isMe}
								canImpersonate={canImpersonate(profile.user)}
								isMe={isMe}
								profile={profile}
								onContactPress={openContact}
								onEdit={onEditProfile}
								onImpersonate={() => impersonate(profile.user.id)}
							/>
							<ProfileMyRole isInvisible={isSectionInvisible('myRole')} myRole={profile.myRole} />
							<div className={styles.profile__smallRow}>
								<ProfileBiography
									biography={profile.biography}
									canEdit={isMe}
									isHidden={isPropertyHidden('biography')}
									isInvisible={isSectionInvisible('chairBiography') || (!profile?.biography?.length && !isMe)}
									title={t('Biography')}
									onEdit={onEditBiography}
								/>
								<ProfileInterests
									canEdit={isMe}
									editButtonStyle={styles.interestsEditButton}
									interests={profile.interests}
									isInvisible={isSectionInvisible('chairInterests')}
									title={t('Interests')}
									onEdit={onEditInterests}
								/>
							</div>
							<ProfileLeadingGroups
								isInvisible={isSectionInvisible('chairLeadsCommunities')}
								profileId={profile.id}
								onPressCommunity={openCommunity}
								onPressNomination={openNomination}
								onPressRequestAttend={openRequestAttend}
							/>
							<div className={styles.profile__rowGroup}>
								<ProfileExpertise
									canEdit={isMe}
									expertiseSpheres={profile.expertise}
									isInvisible={isSectionInvisible('expertise')}
									onEdit={onEditExpertise}
								/>
								<ProfileInvestmentExpertise
									canEdit={isMe}
									investmentExpertise={profile.investmentExpertise}
									investmentLevel={profile.investmentLevel}
									isInvisible={isSectionInvisible('investmentExpertise')}
									onEdit={onEditInvestmentExpertise}
								/>
								<ProfileInterests
									canEdit={isMe}
									interests={profile.interests}
									isInvisible={isSectionInvisible('interests')}
									onEdit={onEditInterests}
								/>
							</div>
							<ProfileActivities
								activities={activities}
								canEdit={isMe}
								isHidden={isPropertyHidden('activities')}
								isInvisible={isSectionInvisible('activities')}
								isLoading={isActivitiesLoading}
								onEdit={onEditActivities}
								onPressActivity={openActivity}
							/>
							<ProfileEvents
								canEdit={isMe}
								isHidden={isPropertyHidden('events')}
								isInvisible={isSectionInvisible('events')}
								isMe={isMe}
								profileId={profile.id}
								onEdit={onEditEvents}
								onPressEvent={openEventOrMeeting}
								onSeeAll={onSeeAllEvents}
							/>
							<ProfileGroups
								isInvisible={isSectionInvisible('groups')}
								isMe={isMe}
								profileId={profile.id}
								onPressGroup={openGroup}
							/>
							<div className={styles.profile__smallRow}>
								<ProfileBiography
									biography={profile.biography}
									canEdit={isMe}
									isHidden={isPropertyHidden('biography')}
									isInvisible={isSectionInvisible('biography')}
									onContactPress={openContact}
									onEdit={onEditBiography}
								/>
								<ProfileDetails
									canEdit={isMe}
									fullName={fullName}
									hideProperties={profile.hideProperties}
									isInvisible={isSectionInvisible('links')}
									links={profile.links}
									registeredAt={profile.since}
									roles={profile.user.roles}
									onEdit={onEditDetails}
								/>
							</div>
							{isInvestTabShowed && isProfileInvestEnabled && !isSectionInvisible('deals') && (
								<>
									<Gap gap={40} />
									<ProfileDeals isMe={isMe} profileId={profile.id} userName={profile.user.firstName} />
								</>
							)}
						</div>
					</div>
				) : null}
			</PageLayout>
		</Page>
	);
};

export default ProfilePage;
