import React, { useMemo } from 'react';
import { Page } from '../../components';
import { useTranslation } from '@hooks';
import { ActivityIndicator, Gap } from '@ui-kit';
import TipList from '@ui-modules/tipJar/components/TipList';
import { useTipCategoriesQuery } from '@ui-modules/tipJar/hooks/useTipCategoriesQuery';
import { useAllTipsQuery } from '@ui-modules/tipJar/hooks/useAllTipsQuery';
import EmptyTipList from '@ui-modules/tipJar/components/EmptyTipList/EmptyTipList';
import TipFiltersDropdown from '@ui-modules/tipJar/components/TipFiltersDropdown';
import styles from './AllTipsPage.module.css';
import { useSearchParams } from 'react-router-dom';

const AllTipsPage = () => {
	const { t } = useTranslation();
	const [searchParams, setSearchParams] = useSearchParams();

	const selectedTipCategories = useMemo(() => {
		const categoriesFromQuery = searchParams.get('categories');
		return categoriesFromQuery ? categoriesFromQuery.split(',') : [];
	}, [searchParams]);
	const {
		data: tips,
		isLoading: isTipsLoading,
		fetchMore,
		isFetchingNextPage,
	} = useAllTipsQuery({ categories: selectedTipCategories });

	const { data: tipCategories = [] } = useTipCategoriesQuery();

	const handleApplyCategories = (categories: string[]) => {
		if (categories.length > 0) {
			searchParams.set('categories', categories.join(','));
		} else {
			searchParams.delete('categories');
		}
		setSearchParams(searchParams);
	};
	return (
		<Page title={t('My Tips Page')}>
			<>
				<div className={styles.allTipsPage__filtersContainer}>
					{tipCategories ? (
						<TipFiltersDropdown
							initialSelectedCategories={selectedTipCategories}
							tipCategories={tipCategories}
							onApply={handleApplyCategories}
						/>
					) : null}
				</div>
				<Gap gap={16} />
				{!isTipsLoading && tips && tips.length > 0 && (
					<TipList
						data={tips}
						isFetchingNextPage={isFetchingNextPage}
						isLoading={isTipsLoading}
						onEndReached={fetchMore}
					/>
				)}
			</>
			{!isTipsLoading && tips && tips.length === 0 && (
				<EmptyTipList
					message={t("Sorry, we couldn't find any results for the category you selected. Please alter your input.")}
				/>
			)}
			{isTipsLoading && <ActivityIndicator size="medium" type="fit" />}
		</Page>
	);
};

export default AllTipsPage;
