import { Page } from '../../components';
import { useTranslation, useParams } from '@hooks';
import { useTipQuery } from '@ui-modules/tipJar/hooks/useTipQuery';
import { useEffect } from 'react';
import { ActivityIndicator, BookmarkIcon, Button, EditIcon, ShareOutlineIcon } from '@ui-kit';
import styles from './TipPage.module.css';
import { clsx } from '@utils';
import ScreenHeader from '@ui-kit/components/ScreenHeader';
// TODO, Just start implementation
const TipPage = () => {
	const { t } = useTranslation();
	const { tipId } = useParams();
	const { data: tip, isLoading } = useTipQuery(tipId || '');
	useEffect(() => {
		if (!tip) return;
	}, [tip]);
	return (
		<Page title={t('Tip Selected Page')}>
			{isLoading ? (
				<ActivityIndicator size="medium" type="fit" />
			) : !tip ? (
				<div> Tip not found </div>
			) : (
				<div className={styles.tipSelectedPage}>
					<div className={clsx(styles.tipSelectedPage__header)}>
						<ScreenHeader title={tip.title}>
							<div className={styles.tipSelectedPage__header_buttons}>
								<Button
									icon={<EditIcon height={14} width={14} />}
									title={t('Review')}
									type="outline"
									variant="medium"
								/>
								<Button
									icon={<ShareOutlineIcon height={14} width={14} />}
									title={t('Share')}
									type="outline"
									variant="medium"
								/>
								<Button icon={<BookmarkIcon height={14} width={14} />} type="outline" variant="medium" />
							</div>
						</ScreenHeader>
					</div>
				</div>
			)}
		</Page>
	);
};

export default TipPage;
