import { Page, TabWrapper } from '../../components';
import { useNavigate, useParams, useRouteSearchQueryState, useToggle, useTranslation } from '@hooks';
import styles from './TipJarPage.module.css';
import { clsx } from '@utils';
import { Button, EditIcon, SearchableCompactScreenHeader, Tabs } from '@ui-kit';
import { useState } from 'reinspect';
import { ROUTES } from '@constants';
import type { TTipsTabName } from '@typings';
import TipJarIntroModal from '@ui-modules/tipJar/components/TipJarIntroModal';
import MyTipsPage from '../MyTipsPage/MyTipsPage';
import { useEffect } from 'react';
import AllTipsPage from '../AllTipsPage/AllTipsPage';

const TipJarPage = () => {
	const { t } = useTranslation();
	const navigate = useNavigate();
	const [routeSearchQuery, setRouteSearchQuery] = useRouteSearchQueryState();
	const [searchQuery, setSearchQuery] = useState(routeSearchQuery, 'TipJarPage:searchQuery');
	const [isSearchFocused, setIsSearchActive] = useToggle(false);
	const [currentTabIndex, setCurrentTabIndex] = useState(0, 'TipJarPage:currentTabIndex');
	const { tipsTabName } = useParams();

	const showSearchResults = isSearchFocused || !!searchQuery.length;
	const tipTabs: TTipsTabName[] = ['all', 'my', 'bookmarked'];
	const currentTabFromRoute = tipTabs.findIndex((tab) => tab === tipsTabName) || 0;

	const handleChangeTab = (index: number) => {
		setCurrentTabIndex(index);
		const nextFeedName = tipTabs[index];
		navigate(ROUTES.tipJarTab(nextFeedName));
	};

	useEffect(() => {
		setCurrentTabIndex(currentTabFromRoute);
	}, [currentTabFromRoute, routeSearchQuery]);

	return (
		<Page title={t('Tip Jar')}>
			<TipJarIntroModal />
			<div className={styles.tipJarPage}>
				<div className={clsx(styles.tipJarPage__header)}>
					<SearchableCompactScreenHeader
						customButtonsElement={
							<Button icon={<EditIcon height={14} width={14} />} title={t('New')} type="outline" variant="small" />
						}
						placeholder={t('Search tips')}
						searchQuery={searchQuery}
						searchTitle={t('Search')}
						title={t('Tip Jar')}
						titleAs="h3"
						onBlur={() => setIsSearchActive(false)}
						onChangeSearchQuery={setSearchQuery}
						onFocus={() => setIsSearchActive(true)}
						onSearchCanceled={() => setRouteSearchQuery('')}
					/>
				</div>
				{showSearchResults ? (
					<>{/* TODO: add SearchResultList */}</>
				) : (
					<Tabs selectedIndex={currentTabIndex} onSelect={handleChangeTab}>
						<Tabs.List>
							{tipTabs.map((tab) => {
								switch (tab) {
									case 'all':
										return <Tabs.Tab key="all">{t('All Tips')}</Tabs.Tab>;
									case 'my':
										return <Tabs.Tab key="my">{t('My Tips')}</Tabs.Tab>;
									case 'bookmarked':
										return <Tabs.Tab key="bookmarked">{t('Bookmarks')}</Tabs.Tab>;
								}
							})}
						</Tabs.List>
						{tipTabs.map((tab) => {
							switch (tab) {
								case 'all':
									return (
										<Tabs.Panel key="all">
											<TabWrapper>
												<AllTipsPage />
											</TabWrapper>
										</Tabs.Panel>
									);
								case 'my':
									return (
										<Tabs.Panel key="my">
											<TabWrapper>
												<MyTipsPage />
											</TabWrapper>
										</Tabs.Panel>
									);
								case 'bookmarked':
									return (
										<Tabs.Panel key="bookmarked">
											<TabWrapper>
												<>bookmarked</>
											</TabWrapper>
										</Tabs.Panel>
									);
							}
						})}
					</Tabs>
				)}
			</div>
		</Page>
	);
};

export default TipJarPage;
