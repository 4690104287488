import { useService, usePaginatedInfiniteQuery } from '@hooks';
import { dayjs } from '@utils';
import { makeTipMock } from '@mocks';
import type { TTip } from '@typings';

export const useMyTipsQuery = () => {
	const reactQuery = useService('ReactQueryService');

	return usePaginatedInfiniteQuery<TTip>(
		reactQuery.queryKeys.myTips(),
		async ({ pageParam = 1 }) => {
			await new Promise((resolve) => setTimeout(resolve, 1500));
			const tips = Array.from({ length: 10 }, () => makeTipMock());
			return {
				data: tips,
				meta: { hasNext: pageParam <= 2, hasPrevious: false },
				page: Number(pageParam),
			};
		},
		{
			staleTime: dayjs.duration(15, 'minutes').asMilliseconds(),
			cacheTime: dayjs.duration(15, 'minutes').asMilliseconds(),
		},
	);
};
