import React, { useEffect, useRef } from 'react';
import clsx from 'clsx';
import { Avatar } from '@ui-kit';
import styles from './SuggestionListItem.module.css';

import type { SuggestionDataItem } from 'react-mentions';
import type { TFullUser } from '@typings';

const SuggestionListItem = ({
	suggestion,
	highlightedDisplay,
	focused,
	onClickHandler,
	onSelectHandler,
}: ISuggestionListItemProps) => {
	const ref = useRef<HTMLDivElement | null>(null);

	useEffect(() => {
		if (focused && ref && 'current' in ref && ref.current) {
			ref?.current?.scrollIntoView({
				behavior: 'smooth',
				block: 'nearest',
			});
		}
	}, [focused]);

	return (
		<div
			className={clsx(focused ? styles.mention_focused : '', styles.mention)}
			key={suggestion.id}
			ref={ref}
			role="button"
			tabIndex={0}
			onClick={(event) => onClickHandler?.(event, suggestion)}
			onKeyPress={(event) => onClickHandler?.(event, suggestion)}
			onMouseEnter={() => onSelectHandler?.(suggestion)}
		>
			<div className={styles.mentionContent}>
				<Avatar
					outline={Boolean(suggestion?.roles?.includes('ROLE_CHAIR'))}
					size={40}
					source={suggestion.avatar?.contentUrl}
					title={suggestion.display}
				/>
				<div className={styles.mentionContent__description}>
					<p
						className={clsx(
							styles.mentionContent__highlightedDisplayName,
							focused && styles.mentionContent__highlightedDisplayName_focused,
						)}
					>
						{highlightedDisplay}
					</p>
					{!!suggestion?.communitiesForBadge?.shortNames?.length && (
						<p className={styles.mentionContent__communities}>
							{suggestion.communitiesForBadge?.shortNames.join(' • ')}
						</p>
					)}
				</div>
			</div>
		</div>
	);
};

export type TSuggestion = Partial<TFullUser> & SuggestionDataItem;

export interface ISuggestionListItemProps {
	suggestion: TSuggestion;
	highlightedDisplay: React.ReactNode;
	focused: boolean;
	onClickHandler?: (event: React.BaseSyntheticEvent, suggestion: TSuggestion) => void;
	onSelectHandler?: (item: TSuggestion) => void;
}

export default SuggestionListItem;
