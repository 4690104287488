import styles from './TipJarIntroModal.module.css';
import { useTranslation } from '@hooks';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from '@constants';
import { FeatureSlider } from '@ui-kit';
import {
	ModalHeader,
	FourthPageBody,
	ThirdPageBody,
	SecondPageBody,
} from '@ui-modules/tipJar/components/TipJarIntroModal/FeatureComponents';
import { useState } from 'reinspect';
let isModalOpen = false;

const TipJarIntroModal = () => {
	const { t } = useTranslation();
	const navigate = useNavigate();

	const [isTipJarFeatureIntroduced, setTipJarFeatureIntroduced] = useState(false); // TODO implement mutation hook which sets use feature introduced when API is ready

	const dismissModal = () => {
		setTipJarFeatureIntroduced(true);
		isModalOpen = true;
	};

	const onOpenNewTip = () => {
		navigate(ROUTES.tipJarTab('new-tip'));
		setTipJarFeatureIntroduced(true);
	};

	return (
		<FeatureSlider
			slides={[
				{
					header: (
						<div className={styles.firstPageHeader}>
							<ModalHeader mode="large" />
						</div>
					),
					body: (
						<div className={styles.firstPageBody}>
							<p>{t('Continue to learn what you can do...')}</p>
						</div>
					),
					name: 'Swipe to learn what you can do',
				},
				{
					header: <ModalHeader />,
					body: <SecondPageBody />,
					name: 'Share insights with your Group and others',
				},
				{
					header: <ModalHeader />,
					body: <ThirdPageBody />,
					name: 'Our secure AI makes it fast and easy',
				},
				{
					header: <ModalHeader />,
					body: <FourthPageBody onDismiss={dismissModal} onOpenMyAssetAllocation={onOpenNewTip} />,
					name: 'Try it now. Create your first Tip!',
				},
			]}
			visible={!isTipJarFeatureIntroduced && !isModalOpen}
		/>
	);
};

export default TipJarIntroModal;
