import { useNavigate, useEffect, useService } from '@hooks';
import { ROUTES } from '@constants';
import type { TCommunity, TNetworkTabName } from '@typings';

/** Calculates current tab and handles behavior of navigation between tabs.
 *  It exists specifically for the NetworkTabsPage.
 */
export const useNetworkTabsHandler = (
	networkId: TCommunity['id'] | undefined,
	tabNames: TNetworkTabName[],
	currentTabName: string,
	networkName: string,
) => {
	const navigate = useNavigate();
	const analytics = useService('AnalyticsService');

	const currentTabIndex = currentTabName ? tabNames.findIndex((tab) => tab === currentTabName) : 0;
	const onChangeTab = (index: number) => {
		const tabName: TNetworkTabName = tabNames[index] ?? tabNames[0];
		networkId && navigate(ROUTES.networkTab(networkId, tabName));
		analytics.trackEvent('NetworkInteractions', { tab_name: tabName, page_name: networkName });
	};

	useEffect(
		function redirectToDefaultTabIfItUnknown() {
			if (currentTabIndex === -1) {
				const tabName: TNetworkTabName = tabNames.find((tabName) => tabName === 'members') ?? 'about';
				networkId && navigate(ROUTES.networkTab(networkId, tabName), { replace: true });
			}
		},
		[currentTabIndex],
	);

	useEffect(() => {
		analytics.trackEvent('NetworkInteractions', {
			tab_name: currentTabName as TNetworkTabName,
			page_name: networkName,
		});
	}, []);

	return { currentTabIndex, onChangeTab };
};
